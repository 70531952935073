import type { NitroFetchOptions, NitroFetchRequest } from "nitropack"

interface DefaultResponse<T> {
  data: T
  meta?: { total: number }
}

type RequestOptions = NitroFetchOptions<
  NitroFetchRequest,
  // This is pretty ugly but it satisfies nitro types
  | "delete"
  | "get"
  | "head"
  | "patch"
  | "post"
  | "put"
  | "connect"
  | "options"
  | "trace"
>

/**
 * Formats array params to be recognized correctly by the server
 * For nested params to be always in the correct format: `filters[status][]=active&filters[status][]=pending`
 * In some cases params are arriving here in distinct formats:
 * Input: {"filters[status][]": ["complete"], "filters[step][]": ["1","2"]}
 * Output: filters[status][]=complete&filters[step][]=1&filters[step][]=2
 * OR
 * Input: {"filters[order_type]": ["BusinessService","PassportService"]}
 * Output: filters[order_type]=BusinessService&filters[order_type]=PassportService
 */
const formatArrayParams = (params: RequestOptions["params"]): URLSearchParams => {
  const formattedParams = new URLSearchParams()

  // Assertion is safe request won't run this method unless params are passed
  Object.entries(params!).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      const baseKey = key.endsWith("[]")
        ? key
        : `${key}${key.includes("filters[") ? "[]" : ""}`
      value.forEach((v) => {
        formattedParams.append(baseKey, String(v))
      })
    }
    else {
      formattedParams.append(key, String(value))
    }
  })

  return formattedParams
}

export const useFetchAuth = async <T = unknown>(url: string, opts?: RequestOptions): Promise<DefaultResponse<T>> => {
  const config = useRuntimeConfig()
  const { data: session } = useSessionState()
  let signOut: any = null

  if (!import.meta.server) {
    const sess = useSession()
    signOut = sess.signOut
  }
  const headers: HeadersInit = {
    ...(opts?.headers || {}), ...(session.value.user.accessToken
      && { Authorization: `Bearer ${session.value.user.accessToken}` })
  }

  let apiBase = ""
  if (import.meta.server) {
    apiBase = config.internalApiUrl
  }
  else {
    apiBase = config.public.baseUrl
  }

  try {
    const { params: _, ...restOpts } = opts || {}
    const formattedParams = opts?.params ? formatArrayParams(opts.params) : new URLSearchParams()

    const response = await $fetch<DefaultResponse<T>>(
      `${apiBase}${url}${formattedParams.toString() ? `?${formattedParams}` : ""}`, { ...restOpts, headers }
    )
    return response
  }
  catch (error) {
    if (error.status === 401) {
      if (!import.meta.server) {
        signOut()
      }
    }
    else if (error.status === 403) {
      navigateTo("/403")
    }
    console.error("error", error.status)
    throw createError({
      data: (error as FetchError).response
    })
  }
}
